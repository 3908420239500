const ConfigFile = process.env.REACT_APP_CONFIG_FILE;

class ConfigService {

  static async getConfig() {
    const options = {
      method: 'GET',
      credentials: "same-origin",
      headers: {
        'content-type': 'application/json'
      }
    }

    const response = await fetch(window.location.origin +'/'+ ConfigFile, options);

    var json = response.json();
    return json;
  }
}

export default ConfigService;