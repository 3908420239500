import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

//import any reducers here
import { auth, notification } from "smart-component-library";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    //Add all your reducers below here...
    auth,
    notification,
  });

export default createRootReducer;
