import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { LoginTemplate, Spinner } from "smart-ui-library";
import { replace } from "connected-react-router";

import { requestPasswordReset, resetPassword, getBrandLogo } from "../../redux/actions/userActions";
import { login } from "smart-component-library";

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isBusy: false,
      loginState: null,
      brandLogo: null,
      errorMessage: null,
      description: null,
      email: null,
    };

    this.onLoginClick = this.onLoginClick.bind(this);
    this.onResendCodeClick = this.onResendCodeClick.bind(this);
    this.onVerifyClick = this.onVerifyClick.bind(this);
    this.onForgotPasswordClick = this.onForgotPasswordClick.bind(this);
    this.onRequestPasswordReset = this.onRequestPasswordReset.bind(this);
    this.onResetPassword = this.onResetPassword.bind(this);
    this.onUserInteraction = this.onUserInteraction.bind(this);
    this.login = this.login.bind(this);
  }

  showPendingSpinnerTimeoutID;

  onResendCodeClick(username, password) {
    this.login(username, password, null);
  }

  onVerifyClick(username, password, verificationCode) {
    if (!verificationCode) {
      this.setState({
        errorMessage: "Please provide a verification code",
      });
    }
    else {
      this.login(username, password, verificationCode);
    }
  }

  onLoginClick(username, password) {
    if ((!username || !password)) {
      this.setState({
        errorMessage: "Please provide a username and password",
      });
    }
    else {
      this.login(username, password, null);
    }
  }

  login(username, password, verificationCode) {
    let notStillBusy = this.setIsBusy();
    if (notStillBusy) {
      let prevLoginState = this.state.loginState;
      this.setPendingStateSoon();
      login(username, password, verificationCode)
        .then(() => {
          console.log("login successful, navigating...");
          let url = `/`;
          if (this.state.loginState !== LoginTemplate.loginStates.ResetPassword) {
            if (window.location.search) {
              //We do not check any routes here, or do any logic around routing,
              //The AuthenticateRoute component/route is responsible for doing that,
              //so we just navigate to that route with any redirectUrl included (search).
              url += window.location.search;
            }
          }
          console.log(`navigating to ${url}`);
          this.props.navigateTo(url);
        })
        .catch((data) => {
          //any error should already be passed to the component via redux.
          console.log("login not successful... checking 2fa");
          if (data.payload?.twoFactorAuthNeeded) {
            this.setState({
              loginState: LoginTemplate.loginStates.Verify,
              description: data.payload.instruction,
              errorMessage: data.payload.message,
            });
          } else {
            this.setState({
              loginState: prevLoginState,
              errorMessage: data.payload?.message || data.message || "unknown error!",
            });
          }
        })
        .finally(() => {
          this.clearPendingState();
          this.setNotBusy();
        });
    }
    else {
      console.log("still busy!");
    }
  }

  setIsBusy() {
    if (!this.state.isBusy) {
      this.setState({
        isBusy: true,
      });
      return true;
    }
    return false;
  }

  setNotBusy() {
    this.setState({
      isBusy: false,
    });
  }

  onForgotPasswordClick() {
    this.setState({
      errorMessage: null,
      loginState: LoginTemplate.loginStates.ForgotPassword,
    });
  }

  onResetPassword(username, password, confirmPassword, token) {
    let prevLoginState = this.state.loginState;
    if (password !== confirmPassword) {
      this.setState({
        loginState: prevLoginState,
        errorMessage: "Passwords do not match",
      });
    }
    else if (password.length < 8) {
      this.setState({
        loginState: prevLoginState,
        errorMessage: "Password must be at least 8 characters",
      });
    }
    else {
      let notStillBusy = this.setIsBusy();
      if (notStillBusy) {
        this.setPendingStateSoon();
        this.props
          .resetPassword(username, password, token)
          .then(() => {
            console.log("password reset complete, will now login...");
            this.clearPendingState();
            this.setNotBusy();
            this.login(username, password, null);
          })
          .catch((error) => {
            console.log("error resetting password, setting error state...");
            this.setState({
              loginState: prevLoginState,
              errorMessage: error.payload?.message || error.message,
            });
          })
          .finally(() => {
            this.clearPendingState();
            this.setNotBusy();
          });
      }
    }
  }

  onRequestPasswordReset(username) {
    //validate that a username, password and verification codes are provided
    if (!username) {
      this.setState({
        errorMessage: "Please provide a username",
      });
    } else {
      let notStillBusy = this.setIsBusy();
      if (notStillBusy) {
        let prevLoginState = this.state.loginState;
        this.setPendingStateSoon();
        this.props
          .requestPasswordReset(username)
          .then(() => {
            this.setState({
              loginState: prevLoginState,
            });
          })
          .catch((error) => {
            this.setState({
              loginState: prevLoginState,
              errorMessage: error.payload?.message || error.message,
            });
          })
          .finally(() => {
            this.clearPendingState();
            this.setNotBusy();
          });
      }
    }
  }

  onUserInteraction() {
    this.clearPendingState();
    this.setState({
      errorMessage: null,
    });
  }

  clearPendingState() {
    clearTimeout(this.showPendingSpinnerTimeoutID);
  }

  setPendingStateNow() {
    clearTimeout(this.showPendingSpinnerTimeoutID);
    this.setState({
      loginState: LoginTemplate.loginStates.Pending,
    });
  }

  setPendingStateSoon() {
    clearTimeout(this.showPendingSpinnerTimeoutID);
    this.showPendingSpinnerTimeoutID = setTimeout(() => this.setPendingStateNow(), 2000);
  }

  componentDidMount() {
    let loginState = LoginTemplate.loginStates.Login;
    let description = null;
    let pathname = window.location.pathname;
    if (pathname.includes("resetpassword")) {
      loginState = LoginTemplate.loginStates.ResetPassword;
      description = "Password must be at least 8 characters";
    }
    this.props
      .getBrandLogo()
      .then((data) => {
        this.setState({
          brandLogo: "data:image/png;base64,".concat(data.value),
        });
      })
      .catch((error) => {
        console.log("Login error: " + JSON.stringify(error));
      })
      .finally(() => {
        this.setState({
          loginState: loginState,
          description: description,
        });
      });
  }

  render() {
    const loginStatesProps = {
      loginState: this.state.loginState,
      description: this.state.description,
      errorMessage: this.state.errorMessage,
      onLoginClick: this.onLoginClick,
      onResendCodeClick: this.onResendCodeClick,
      onVerifyClick: this.onVerifyClick,
      onForgotPasswordClick: this.onForgotPasswordClick,
      resetPassword: this.onResetPassword,
      onUserInteraction: this.onUserInteraction,
      onRequestPasswordLink: this.onRequestPasswordReset,
    };

    if (this.state.loginState) {
      console.log("Render: login state: " + this.state.loginState);
      return <LoginTemplate brandLogo={this.state.brandLogo} loginStatesProps={loginStatesProps} />;
    } else {
      console.log("Render: login state is null, so showing spinner");
      return <Spinner />;
    }
  }
}

const stateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  token: state.auth.token,
});

const dispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getBrandLogo,
      requestPasswordReset,
      resetPassword,
      navigateTo: (url) => replace(url),
    },
    dispatch,
  );

export default connect(stateToProps, dispatchToProps)(Login);
