import UserService from "../../services/UserService";

export const GET_BRAND_LOGO = "GET_BRAND_LOGO";
export const RESET_PASSWORD = "RESET_PASSWORD";

export const getBrandLogo = () => {
  return {
    async payload() {
      return UserService.getBrandLogo();
    },
  };
};

export const requestPasswordReset = (username) => {
  return {
    async payload() {
      return UserService.requestPasswordReset(username);
    },
  };
};

export const resetPassword = (username, newPassword, token) => {
  return {
    async payload() {
      return UserService.resetPassword(username, newPassword, token);
    },
  };
};
