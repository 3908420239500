import { getResponseFromUrl } from "smart-component-library";

const AUTH_API = process.env.REACT_APP_AUTH_API;
const APPLICATION_ID = process.env.REACT_APP_APPLICATION_ID;

/** This service makes calls to UserApi, but currently only for some /auth endpoints 
 * - getting brand logo based on url
 * - requesting password reset
 */
class UserService {
  static async getBrandLogo() {
    let hostName = window.location.hostname;
    var url = `${AUTH_API}/brandlogo?hostName=${hostName}`;
    return await getResponseFromUrl(url);
  }

  /**
   * This will send a reset link to the user's email address.
   */
  static async requestPasswordReset(username) {
    let hostName = window.location.hostname;
    const url = `${AUTH_API}/requestresetpassword`;
    const payload = { username, application: APPLICATION_ID, hostName: hostName };
    return await getResponseFromUrl(url, "POST", JSON.stringify(payload));
  }

  /**
   * This will reset the users password to what is passed in here.
   */
  static async resetPassword(username, newPassword, token) {
    const url = `${AUTH_API}/resetpassword`;
    const payload = { username: username, password: newPassword, token: token };
    return await getResponseFromUrl(url, "POST", JSON.stringify(payload));
  }
}

export default UserService;
