import React, { Component } from "react";
import { connect } from "react-redux";
import "./Logout.css";
import { Spinner } from "smart-ui-library";
import { bindActionCreators } from "redux";
import { replace } from "connected-react-router";
import { logout } from "smart-component-library";

export class Logout extends Component {
  componentDidMount() {
    logout()
      .then(() => {
        console.info("user logged out");
      })
      .catch((error) => {
        if (error.response?.status) {
          console.info(
            "Logout api call was not successfull, but it's ok, just going to redirect to login anyway: status: " +
              error.response.status,
          );
        } else if (error.message) {
          console.info("Logout api call was not successfull, error: " + error.message);
        } else {
          throw error;
        }
      })
      .finally(() => {
        let url = `/login`;
        if (window.location.search) {
          url += window.location.search;
        }
        this.props.navigateTo(url);
      });
  }

  render() {
    return (
      <div id="logout" className="logout">
        <Spinner />
      </div>
    );
  }
}

const dispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      navigateTo: (url) => replace(url),
    },
    dispatch,
  );

export default connect(null, dispatchToProps)(Logout);
