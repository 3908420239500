import { createStore, applyMiddleware, compose } from 'redux'
import { createBrowserHistory } from 'history'
import thunk from 'redux-thunk'

import createRootReducer from '../redux/reducers'

import { routerMiddleware } from 'connected-react-router'

//Additional Middleware
import promiseMiddleware from 'redux-promise-middleware';
//import authenticationMiddleware from '../services/AuthenticationMiddleware'

export const history = createBrowserHistory({ basename: '/auth' })

//const initialState = {}
const enhancers = []
//const middleware = [routerMiddleware(history), authenticationMiddleware, thunk, promiseMiddleware]
const middleware = [routerMiddleware(history), thunk, promiseMiddleware]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    composedEnhancers
  )

  return store
}
