import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";

import configureStore, { history } from "./store";

import { ErrorBoundary } from "smart-component-library";
import App from "./containers/app/App";

const store = configureStore(/* provide initial state if any */);
const target = document.querySelector("#root");

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ErrorBoundary displayMode={ErrorBoundary.displayModes.ApiError}>
        <App />
      </ErrorBoundary>
    </ConnectedRouter>
  </Provider>,
  target,
);
